<template>
	<div class="-edit-main">
		<!-- 表单 -->
		<el-form :model="form" :rules="rules" ref="form" class="-form" @submit.native.prevent>
			<el-form-item class="-full" label="内容详情" prop="content">
				<vue-ueditor-wrap class="-editor" v-model="form.content" :config="editorConfig" editor-id="editor-demo-01"></vue-ueditor-wrap>
			</el-form-item>
			
			<!-- 底部 -->
			<div class="-edit-footer">
				<el-button type="primary" native-type="submit" title="按下'Ctrl + Enter'键可快速提交" @click="submitData()">提 交</el-button>
				<el-button title="按下'Esc'可快速关闭" @click="view.DrawerVisible = false">取 消</el-button>
			</div>
		</el-form>
	</div>
</template>

<script>
import { ref } from 'vue'

export default {
	data() {
		return {
			view: {
				width: '130px'
			},
			form: {
				content: '',
			},
			rules: {
				content: [
					{ required: true, message: '内容', trigger: 'blur' },
				]
			}
		}
	},
	created() {
		this.editorConfig = {
			UEDITOR_HOME_URL: '/UEditor/',
			initialFrameWidth:434,
			initialFrameHeight:536,
			toolbars: [[
			     'fullscreen', 'source', 'preview', '|', 
			     'paragraph', 'fontfamily', 'fontsize', '|',
			     'bold', 'italic', 'underline', 'fontborder', 'strikethrough', 'superscript', 'subscript', 'removeformat', 'formatmatch', 'autotypeset', 'pasteplain', '|', 
				 'forecolor', 'backcolor', 'insertorderedlist', 'insertunorderedlist', '|',
			     'indent', 'rowspacingtop', 'rowspacingbottom', 'lineheight', '|',
			     'justifyleft', 'justifycenter', 'justifyright', 'justifyjustify', '|', 
			     'link', 'anchor', '|', 
				 'imagenone', 'imageleft', 'imageright', 'imagecenter', '|',
			     'simpleupload', 'insertimage', 'scrawl', 'insertvideo', 'music', 'attachment', 'map', 'insertframe', 'background', '|',
			     'horizontal', 'inserttable', 
			 ]]
		}
	},
	mounted() {
		//获取设置信息
		this.form.type = this.$route.query.key
		this._post('/Common/Setting/getRule', {type: this.form.type}, (res)=>{
			this.form.content = ref(res.data.content)
		})
	},
	methods: {
		
		//提交表单
		submitData() {
			this.$refs['form'].validate((valid) => {
				if (valid) {
					this._post('/Common/Setting/setRule', this.form, (res)=>{
						this._msg('更新成功')
					})
				} else return false
			})
			return false
		}	
	}
}
</script>